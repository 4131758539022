import React from 'react'
import Layout from '../components/Layout'
import Container from '../components/Container'
import ReplaceEmailPatterns from '../components/ReplaceEmailPatterns'
import { initI18n } from '../translations'

const Job = props => {
  const i18n = initI18n(props.pageContext.instance, props.pageContext.lang)
  return (
    <Layout {...props}>
      <div className="main-content content-module">
        <Container textWidth>
          <h1>{i18n('job.heading')}</h1>
          <p>{i18n('job.notice')}</p>

          {i18n('job.body', []).map((group, i) => (
            <React.Fragment key={i}>
              <h2>{group[0]}</h2>
              <p>
                <ReplaceEmailPatterns text={group[1]} />
              </p>
            </React.Fragment>
          ))}
        </Container>
      </div>
    </Layout>
  )
}

export default Job
