import React from 'react'
import Email from '../components/Email'

const ReplaceEmailPatterns = ({ text }) => {
  return text.split(/(\{.*?\})/g).map(textPart => {
    if (textPart.match(/^(\{.*?\})$/)) {
      const [label, email] = textPart.replace(/[{}]/g, '').split(':')
      return <Email key={email} email={email} label={label} />
    }

    return textPart
  })
}

export default ReplaceEmailPatterns
